<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">课程列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">推送日志</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
         
       
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="名称"
                align="left"
                prop="state"
                show-overflow-tooltip
              />
              
              <el-table-column
                label="操作时间"
                align="left"
                prop="operationTime"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{scope.row.operationTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="操作人"
                align="left"
                prop="fullname"
                show-overflow-tooltip
              />
          
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>

      </div>
    </div>
  
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "guangzhouCourseList",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    return {
    };
  },
  created() {

  },
  computed: {},
  methods: {
  

    getData(pageNum = 1) {
      const params = {
        courseId:this.$route.query.courseId
      };
      this.doFetch({
        url: "/biz/canton/coursePushRecord",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  
  
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
